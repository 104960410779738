import { defineComponent, ref, watch, onMounted, getCurrentInstance, computed } from '@vue/composition-api';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import PageLoadingBar from '@/shared/components/PageLoadingBar.vue';
import FormServiceItem from '@/shared/components/form/FormServiceItem.vue';
import FormVerticalRadio from '@/shared/components/form/FormVerticalRadio.vue';
import PriceInput from '@/uikit/PriceInput.vue';
import InfoBlock from '@/shared/components/InfoBlock.vue';
import FormSelect from '@/shared/components/form/FormSelect.vue';
import SpaceFacilitiesForm from '@/pages/vendor/space/facilities/forms/SpaceFacilitiesForm';
import { VAT_RATES, ACTIVITIES } from '@/shared/const';
import toJSON from '@/api/base/toJSON';
import getSpace from '@/api/space/getById';
import { cloneDeep, isEqual } from 'lodash';
import Popover from '@/components/Popover.vue';
import useAsyncPage from '@/shared/composables/useAsyncPage';
const getActivityLabel = (type) => {
    switch (type) {
        case ACTIVITIES.WE_MEET:
            return 'We Meet';
        case ACTIVITIES.WE_PRESENT:
            return 'We Present';
        case ACTIVITIES.WE_TRAIN:
            return 'We Train';
        case ACTIVITIES.WE_CELEBRATE:
            return 'We Celebrate';
        default:
            return '';
    }
};
export default defineComponent({
    components: {
        InfoBlock,
        FormSelect,
        FormServiceItem,
        FormVerticalRadio,
        PageLoadingBar,
        Popover,
        PriceInput
    },
    props: {
        revert: {
            type: Boolean,
            default: false
        },
        onSave: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const errors = ref([]);
        const form = ref(new SpaceFacilitiesForm());
        const formLoaded = ref({});
        const instance = getCurrentInstance();
        const loaded = ref(false);
        const saving = ref(false);
        const spaceServices = computed(() => globalThis.$store.getters['$_vendor/spaceServices']);
        const canContinue = computed(() => {
            if (saving.value) {
                checkErrors();
                return !errors.value.length;
            }
            return true;
        });
        const vatRates = computed(() => {
            return [
                {
                    label: globalThis.$i18n.t('onboarding.organisation_details.select_option_vat_21'),
                    value: VAT_RATES.TWENTY_ONE
                },
                {
                    label: globalThis.$i18n.t('onboarding.organisation_details.select_option_vat_9'),
                    value: VAT_RATES.NINE
                },
                {
                    label: globalThis.$i18n.t('onboarding.organisation_details.select_option_vat_none'),
                    value: VAT_RATES.NONE
                }
            ];
        });
        watch(() => spaceServices.value, value => {
            if (value) {
                form.value.loadFromModel(spaceServices.value);
            }
        }, { immediate: true });
        const { dataLoaded, callAPIAction } = useAsyncPage();
        const updateFacilityLimit = (facility, val) => {
            facility.inventoryEnabled = val === 1;
        };
        watch(() => form.value.facilities, () => {
            if (dataLoaded.value) {
                if (!loaded.value) {
                    formLoaded.value = cloneDeep(form.value);
                }
                loaded.value = true;
            }
        }, { deep: true, immediate: true });
        watch(() => [form.value.facilities, form.value.wifi, form.value.wifiPassword], () => {
            if (!formLoaded.value)
                return;
            if (form.value.wifi !== formLoaded.value.wifi ||
                form.value.wifiPassword !== formLoaded.value.wifiPassword) {
                emit('updateSpaceDetails', {
                    data: {
                        wifiName: form.value.wifi,
                        wifiCode: form.value.wifiPassword
                    }
                });
            }
            if (formLoaded.value.facilities &&
                !isEqual(form.value.facilities, formLoaded.value.facilities)) {
                emit('settingsChanged', {
                    page: 'facilities',
                    data: [...form.value.facilities],
                    prevData: [...formLoaded.value.facilities]
                });
            }
            if (form.value.wifi === formLoaded.value.wifi &&
                form.value.wifiPassword === formLoaded.value.wifiPassword &&
                isEqual(form.value.facilities, formLoaded.value.facilities)) {
                emit('noChanges');
            }
        }, { immediate: true, deep: true });
        const refetch = async () => {
            formLoaded.value = {};
            await callAPIAction('$_vendor/loadSpaceServices', root.$route.params).then(() => {
                formLoaded.value = cloneDeep(form.value);
                emit('noChanges');
            });
        };
        watch(() => props.revert, () => {
            if (props.revert) {
                form.value = cloneDeep(formLoaded.value);
                refetch();
            }
        }, { immediate: true });
        watch(() => props.onSave, () => {
            if (props.onSave) {
                refetch();
            }
        }, { immediate: true });
        const checkErrors = () => {
            errors.value = [];
            form.value.validate(instance);
            Object.keys(form.value).forEach(key => {
                const err = form.value.errors(key);
                if (err) {
                    errors.value.push(key);
                }
            });
            // Service VAT rate validation
            const selectedServices = form.value.facilities.filter(service => service.selected && service.onDemand);
            selectedServices.forEach(el => {
                if (el.vat === null && el.price && el.price > 0) {
                    errors.value.push(`vatRate-${el.id}`);
                }
            });
            if (errors.value.length) {
                root.$scrollTo(`#${errors.value[0]}`, 1500, { offset: -200 });
            }
            return errors.value;
        };
        const getVatError = facility => {
            if (errors.value.includes(`vatRate-${facility.id}`)) {
                return globalThis.$i18n.t('onboarding.organisation_details.errors.vat_rate_empty');
            }
        };
        const mainAction = async () => {
            saving.value = true;
            checkErrors();
            if (!errors.value.length) {
                globalThis.$store.commit('$_vendor/SET_SPACE_FACILITIES', form.value);
                globalThis.$router.push({
                    name: ROUTE_LIST_NAMES.ONBOARDING.SPACE.PRICING
                });
            }
        };
        watch(() => form.value, () => {
            if (root.$route.params.spaceId ||
                (!form.value.wifi &&
                    !form.value.wifiPassword &&
                    form.value.facilities.every(facility => !facility.checked)))
                return;
            globalThis.$store.commit('$_vendor/SET_SPACE_FACILITIES', form.value);
        }, { deep: true, immediate: true });
        onMounted(async () => {
            const { spaceId, venueId } = root.$route.params;
            await callAPIAction('$_vendor/loadSpaceFromStorage');
            await callAPIAction('$_vendor/loadSpaceServices', root.$route.params);
            form.value.venueId = venueId;
            if (spaceId) {
                const space = await toJSON(getSpace(spaceId));
                form.value.mandatoryConfirm = true;
                form.value.wifi = space.wifi_name;
                form.value.wifiPassword = space.wifi_code;
                formLoaded.value = cloneDeep(form.value);
            }
        });
        const changeSelectedStatus = (selected, id) => {
            const facility = form.value.facilities.find(el => el.id === id);
            facility.selected = selected;
        };
        return {
            canContinue,
            dataLoaded,
            form,
            loaded,
            vatRates,
            changeSelectedStatus,
            checkErrors,
            getActivityLabel,
            getVatError,
            mainAction,
            updateFacilityLimit
        };
    }
});
