import SpaceFacilitiesModel from '@/shared/models/space/SpaceFacilitiesModel';
class SpaceFacilitiesForm extends SpaceFacilitiesModel {
    mandatoryConfirm;
    constructor(): void {
        super();
        this.mandatoryConfirm = true;
    }
    hideAttributes() {
        return ['id'];
    }
}
export default SpaceFacilitiesForm;
