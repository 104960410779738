import BaseForm from '@/shared/models/BaseForm';
class SpaceFacilitiesModel extends BaseForm {
    id;
    mandatoryConfirm;
    mandatoryFacilities;
    facilities;
    wifi;
    wifiPassword;
    constructor() {
        super();
        this.id = '';
        this.wifi = '';
        this.wifiPassword = '';
        this.mandatoryConfirm = false;
        this.mandatoryFacilities = [
            {
                label: 'Hot and Cold Drinks',
                icon: 'glass',
                selected: false,
                selectable: false,
                price: null,
                fields: null
            },
            {
                label: 'Flip over or Whiteboard',
                icon: 'presentation-check',
                selected: false,
                selectable: false,
                price: null,
                fields: null
            },
            {
                label: 'Accessible Toilets',
                icon: 'podium',
                selected: false,
                selectable: false,
                price: null,
                fields: null
            }
        ];
        this.facilities = [];
    }
    apiAttributes() {
        return {
            id: 'id',
            mandatoryFacilities: 'mandatory-facilities',
            presentFacilities: 'present-facilities'
        };
    }
}
export default SpaceFacilitiesModel;
