import { defineComponent, computed } from '@vue/composition-api';
import FormCheckbox from '@/shared/components/form/FormCheckbox.vue';
import Toggle, { Option } from '@/uikit/Toggle.vue';
export default defineComponent({
    components: {
        Option,
        Toggle,
        FormCheckbox
    },
    props: {
        demandToggle: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: () => { }
        },
        locales: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        return {
            itemForm: computed(() => props.item)
        };
    }
});
